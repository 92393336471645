'use strict';

/**
 * UCPA Web JS
 * Biblioteca principal do componente ucpa-web
 *
 * Segue o module pattern.
 */

var ucpaweb;
ucpaweb = function ($) {
  var app = {};
  var _app = {};
  var $window = $(window);
  var msgPrefix = '[UCPA WEB] ';

  var shave = window.shave;
  var UIkit = window.UIkit;
  var flatpickr = window.flatpickr;

  //
  // MÉTODOS PRIVADOS
  //

  _app.checkWebsiteEnvironment = function () {
    if (location.hostname.match('www.uc.pt')) {
      return 'production';
    }
    return 'development';
  };

  _app.getAppsBaseUrl = function () {
    if (_app.checkWebsiteEnvironment() === 'production') {
      return 'https://apps.uc.pt';
    }
    return 'https://apps.uc.pt';
  };

  _app.buildUserModal = function (loggedUser) {
    if (!$('header .header-tools .user').length || $('header .header-tools .user').length && $('header .header-tools .user .user-picture').length) {
      console.log('Nao existe elemento para carregar a informacao do utilizador');
      return false;
    }

    var appsBaseUrl = _app.getAppsBaseUrl();

    var userMenu = '\n    <a href="#modal-user" uk-toggle="">\n      <div class="user-picture" style="background-image:url(\'' + appsBaseUrl + '/account/picture/' + loggedUser.uid + '?lr\');">\n        <div class="overlay"></div>\n      </div>\n    </a>\n    ';

    var appsHtml = '';
    if (loggedUser.apps) {
      loggedUser.apps.forEach(function (app) {
        appsHtml += '<li><a href="' + app.url + '">' + app.label + '</a></li>';
      });
    }

    var modalHtmlString = '\n    <div class="row modal-content">\n      <div class="container">\n        <div class="user-header">\n          <div class="user-picture"\n            style="background-image:url(\'' + appsBaseUrl + '/account/picture/' + loggedUser.uid + '?hr\');">\n            <div class="overlay"></div>\n          </div>\n          <h2 class="user-fullname">' + (loggedUser.nickname || loggedUser.first_name) + '</h2>\n          <ul class="user-nav">\n              <li><a href="' + appsBaseUrl + '/">Perfil</a></li>\n              <li><a href="' + appsBaseUrl + '/logout">Terminar sess\xE3o</a></li>\n          </ul>\n        </div>\n        <div class="user-apps">\n          <h3>Aplica\xE7\xF5es</h3>\n          <nav class="apps-menu">\n            <ul>\n              ' + appsHtml + '\n            </ul>\n          </nav>\n        </div>\n      </div>\n    </div>';

    $('header .header-tools .user').html(userMenu);
    $('.modal-user .modal-header').after(modalHtmlString);
  };

  /**
   * Configurações dos elementos do header
   */
  _app.initHeader = function () {
    // define o cookie, valor e duração
    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + d.toGMTString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
      $('.cookies').hide();
    }

    // obtém valores do cookie
    function getCookie(cname) {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }

    // se o cookie estiver dfinido, faz coisas
    function checkCookie() {
      var cookie = getCookie('cookie_agreement');
      if (cookie !== '1') {
        $('.cookies').show();
      }
    }
    checkCookie();

    // Esconde a mensagem de cookies e define o cookie
    $('.cookies-close-button').on('click', function (event) {
      setCookie('cookie_agreement', 1, 30);
    });

    // Controlo do botão de abertura da navbar
    $('header .site-menu-toggle, .site-menu-toggle').on('click', function (event) {
      event.preventDefault();

      var w = $window.width();

      // Esconde / Mostra o icone hambuger / fechar
      $(this).find('.icon-close').toggleClass('uk-hidden');

      // Adiciona uma classe ao header
      // para permitir controlar o fundo da navbar
      $('header, .header').toggleClass('expanded');

      if (w < 640) {
        $('header .header-navigation, .header .header-navigation').slideToggle(10);
      } else {
        // $("header .site-menu").slideToggle(10);
      }
    });

    // resolve o problema do overlay que passava para cima do scroll
    $('.user-picture').on('click', function () {
      $('header').css('position', 'relative');
    });
    $('.uk-modal-close').on('click', function () {
      $('header').css('position', 'fixed');
    });

    // ...
    $('.site-menu .main-menu-item').on('click', function (event) {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active').find('.main-submenu').slideUp(50);
        $(this).find('.main-submenu li').hide();
      } else {
        $(this).addClass('active').find('.main-submenu li').show();
        $(this).addClass('active').find('.main-submenu').slideDown(100);
        $(this).siblings().removeClass('active').find('.main-submenu').slideUp(50);
        $(this).siblings().find('.main-submenu li').hide();
      }
    });

    $('.site-menu .main-menu-item').on('mouseleave', function (event) {
      $(this).removeClass('active').find('.main-submenu').slideUp(50);
    });

    $(window).on('scroll', function () {
      if ($(document).scrollTop() > 30) {
        $('header').addClass('small');
      } else {
        $('header').removeClass('small');
      }
    });
  };

  _app.initFooter = function () {
    // Aplica regras caso a página seja carregada em ambiente mobile (< 768)
    if (app.isMobile()) {
      $('footer .footer-sitemap .footer-content').addClass('is-hidden');
    }

    $('footer .footer-sitemap .footer-subtitle').on('click', function (e) {
      e.preventDefault();

      var $sitemap = $('footer .footer-sitemap');
      if ($sitemap) {
        $sitemap.addClass('expanded');
      } else {
        $sitemap.removeClass('expanded');
      }

      if ($(this).siblings().hasClass('is-hidden')) {
        $(this).find('span').attr('uk-icon', 'chevron-up');
        $(this).siblings().slideDown(500).css('display', 'flex');
      } else {
        $(this).find('span').attr('uk-icon', 'chevron-down');
        $(this).siblings().slideUp(500).css('display', 'none');
      }
      $(this).siblings().toggleClass('is-hidden is-visible');
    });
  };

  //
  // MÉTODOS PÚBLICOS
  //

  /**
   * Mobile
   *
   * Verifica a largura da janela é menor que 768
   */
  app.isMobile = function () {
    if (/Mobi/.test(navigator.userAgent)) {
      return true;
    }
    return false;
  };

  app.loadCustomSiteSearch = function ($element) {
    var context = $element.find('input[type=radio]:checked').val();
    var q = $element.find('input[name=q]').val();

    if (context && q) {
      window.location = context + '?q=' + q;
    }
  };

  app.checkAppsSession = function () {
    var endpointBaseUrl = _app.getAppsBaseUrl();
    $.ajax(endpointBaseUrl + '/accounts/rest/auth/details.json', {
      dataType: 'jsonp',
      data: {
        url: window.location.href
      },
      // xhrFields: {
      //     withCredentials: true
      //  },
      timeout: 3000
    }).done(function (data, code) {
      if (!(Object.keys(data.result).length === 0 && data.result.constructor === Object)) {
        _app.buildUserModal(data.result);
      }
    });
  };

  /**
   * Modal Pesquisa
   * Cria a modal de pesquisa caso não exista no DOM
   */
  app.initModalSearch = function (config) {
    // Ignorar a chamada caso já exista o elemento
    if ($('#modal-search').length) {
      return;
    }

    var inputName = 'q';

    var actionUrl = '/';

    if (config && config.inputName) {
      inputName = config.inputName;
    }

    if (config && config.actionUrl) {
      actionUrl = config.actionUrl;
    }

    var modalHtml = '<div class="modal modal-search" id="modal-search">' + '<div class="row modal-header">' + '<div class="container">' + '<div class="uk-grid uk-grid-small uk-flex uk-flex-between">' + '<div class="logo"><img src="images/sprite.svg#logo" uk-svg></div>' + '<div class="close">' + '<a href="#close"><span class="icon-close" uk-icon="close"></span></a>' + '</div></div></div></div>' + '<div class="row modal-content"><div class="container">' + '<form class="uk-search uk-search-large" action="' + actionUrl + '" method="get">' + '<button type="submit"class="uk-search-icon-flip" uk-search-icon></button>' + '<input class="uk-search-input" name="' + inputName + '" type="search" ' + 'placeholder="Pesquisar..." autofocus></form></div></div></div>';

    $('body').append(modalHtml);
  };

  /**
   * Componente Agenda UC
   *
   * Obtém eventos da API web do WP da Agenda UC (agenda.uc.pt)
   * e popula a área corresponde na página
   */
  app.initAgendaComponent = function (category) {
    var $agendaComponent = $('.agendauc-component');

    var $agendaContainerComponent = $agendaComponent.find('.events-container');

    // Verifica se existe o container no DOM
    if (!$agendaComponent.length || !$agendaContainerComponent.length) {
      console.log(msgPrefix + 'Não existe o contentor necessário para incluir os eventos da Agenda.');
      return false;
    }

    // Define o path completo de acordo com a categoria indicada na chamada
    var ts = new Date().getTime();
    var agendaEndpointUrl = 'https://agenda.uc.pt/wp-json/tribe/events/v1/events?_=' + ts + '&per_page=12';
    if (category) {
      agendaEndpointUrl = agendaEndpointUrl + '&categories=' + category;
    }

    // Envia pedido à aplicação da Agenda UC
    $.getJSON(agendaEndpointUrl)

    // Controla os erros do pedido
    .fail(function (jqxhr, textStatus, error) {
      var err = msgPrefix + 'Erro ao pedir eventos da Agenda UC';

      if (jqxhr.status === 400) {
        err = err + ': a categoria não está definida.';
      } else {
        err = err + ': ' + textStatus + ', ' + error;
      }
      console.log(err);
    })

    // Se o pedido for bem sucedido, trata a resposta e apresenta os resultados
    .done(function (data) {
      console.log(msgPrefix + 'Agenda UC: ' + data.events.length + ' evento(s) encontrados.');

      // Formata e envia para o DOM cada evento encontrado
      $.each(data.events, function (i, item) {
        var months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

        var monthStart = months[item.start_date_details.month - 1];

        var monthEnd = months[item.end_date_details.month - 1];

        var start = item.start_date_details.year + item.start_date_details.month + item.start_date_details.day;

        var end = item.end_date_details.year + item.end_date_details.month + item.end_date_details.day;

        var displayDate = '';

        if (start !== end) {
          displayDate = "<div class='dates theme-bg-primary-70'>";

          // se anos diferentes
          if (item.start_date_details.year !== item.end_date_details.year) {
            displayDate += "<div class='date'><span class='day'>" + item.start_date_details.day + "</span><span class='month'>" + monthStart.slice(0, 3) + "</span><span class='year'>" + item.start_date_details.year + "</span></div><div class='date'><span class='day'>" + item.end_date_details.day + "</span><span class='month'>" + monthEnd.slice(0, 3) + "</span><span class='year'>" + item.end_date_details.year + '</span></div>';
          }

          // se meses diferentes no mesmo ano
          if (item.start_date_details.month !== item.end_date_details.month && item.start_date_details.year === item.end_date_details.year) {
            displayDate += "<div class='date as-block'><span class='day'>" + item.start_date_details.day + "</span><span class='month'>" + monthStart.slice(0, 3) + "</span> a </div><div class='date as-block'><span class='day'>" + item.end_date_details.day + "</span><span class='month'>" + monthEnd.slice(0, 3) + "</span></div><span class='year'>" + item.end_date_details.year + '</span></div>';
          }

          // se dias diferentes no mesmo mês
          if (item.start_date_details.day !== item.end_date_details.day && item.start_date_details.month === item.end_date_details.month && item.start_date_details.year === item.end_date_details.year) {
            displayDate += "<div class='date'><span class='day'>" + item.start_date_details.day + ' a ' + item.end_date_details.day + "</span><span class='month'>" + monthStart.slice(0, 3) + "</span><span class='year'>" + item.end_date_details.year + '</span></div>';
          }

          /* displayDate +=
                          "</div><span class=\"spacer\">/</span>" +
                          "<div class='date'><span class='day'>" + item.end_date_details.day + "</span>" +
                          "<span class='month'>" + monthEnd.slice(0, 3) + "</span>" +
                          "<span class='year'>" + item.start_date_details.year + "</span></div></div></div>";
                      */
          displayDate += '</div>';
        } else {
          displayDate = "<div class='dates theme-bg-primary-70'><div class='date'><span class='day'>" + item.start_date_details.day + "</span><span class='month'>" + monthStart.slice(0, 3) + "</span><span class='year'>" + item.start_date_details.year + '</span></div></div>';
        }

        var venue = item.venue.venue;
        if (venue == null) {
          venue = '';
        }

        // Verifica o thumb
        var eventImageUrl = null;
        if (item.image.sizes.hasOwnProperty('medium')) {
          eventImageUrl = item.image.sizes.medium.url;
        } else {
          eventImageUrl = item.image.sizes['blog-thumbnail'].url;
        }

        var html = "<li class='slider-card'><a href='" + item.url + "' class='card-events'><div class='cover' style='background-image: url(" + eventImageUrl + ") !important;'>" + displayDate + "</div><div class='content'>" + "<div class='title'>" + item.title + "</div><div class='venue'>" + venue + '</div></div></a></li>';

        $agendaContainerComponent.append(html);
        $agendaComponent.show();
        shave('.ucpa-web .card-events .title', 120);
      });
    });
  };

  /**
   * INIT
   * ====
   *
   * Funções base principais para controlo de elementos na página.
   * Esta função é executada imediatamente após do objeto que definite esta biblioteca.
   */
  app.init = function () {
    _app.initHeader();
    _app.initFooter();

    UIkit.heightMatch('.slider-container', {
      target: '.uk-card'
    });

    $('form.custom-site-search').on('submit', function (event) {
      event.preventDefault();
      app.loadCustomSiteSearch($(this));
    });

    $('.hide-if-js').hide();

    shave('.truncate-240', 240);
    shave('.truncate-180', 180);
    shave('.truncate-120', 120);
    shave('.truncate-80', 80);
    shave('.truncate-60', 60);
    shave('.truncate-40', 40);

    flatpickr('.datetime-picker, .uk-input[type="datetime-local"]', {
      enableTime: true,
      dateFormat: 'Y-m-dTH:i',
      altFormat: 'Y-m-d H:i',
      altInput: true,
      time_24hr: true
    });

    // Controla o panel de filtragem do arquivo de um repositorio de noticias
    $('.repository-archive .filters-nav .filter-element .uk-dropdown a').on('click', function (event) {
      event.preventDefault();

      var $parentDropdown = $(this).closest('.uk-dropdown');
      var $parentFilterElement = $(this).closest('.filter-element');

      var translateAll = 'Todos';

      if ($parentFilterElement.hasClass('filter-year')) {
        $('.filters-nav input[name="year"]').val($(this).attr('alt'));
      } else {
        $('.filters-nav input[name="tag"]').val($(this).attr('alt'));
        translateAll = 'Todas';
      }

      if ($('html').attr('lang') === 'en') {
        translateAll = 'All';
      }

      $parentFilterElement.find('.selected').html($(this).attr('alt') || translateAll);

      UIkit.dropdown($parentDropdown).hide();
      // $('.filters-nav .filter-year').find('.uk-dropdown').toggleClass('uk-open')
    });

    // Inicia o video quando a modal é aberta
    $('.button-play-modal-video').on('click', function (e) {
      var modalId = $(this).attr('href');
      $(modalId).find('iframe').each(function () {
        this.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      });
    });

    // Termina o video quando for apresentada a modal fullscreen
    $('.modal-video .uk-modal-close').on('click', function (e) {
      $('.modal-video iframe').each(function () {
        this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      });
    });

    // Corrige os placeholder dos formulários
    $('input[placeholder]').each(function () {
      $(this).attr('data-placeholder', this.placeholder);

      $(this).bind('focus', function () {
        // this.placeholder = '';
      });
      $(this).bind('blur', function () {
        this.placeholder = $(this).attr('data-placeholder');
      });
    });

    // $('.video-as-background').YTPlayer({
    //     containment:'self',
    //     autoPlay: true,
    //     mute: true,
    //     showControls: false,
    //     showYTLogo: false,
    //     startAt: 0,
    //     loop: true,
    //     opacity: 1,
    //     optimizeDisplay: true,
    //     quality: 'highres'
    // });

    // ...
    $(document).on('click', '.is-collapsible legend', function (e) {
      $(this).parent('fieldset').toggleClass('is-visible');
      if ($(this).parent('fieldset').hasClass('is-visible')) {
        $(this).find('span').attr('uk-icon', 'chevron-up');
      } else {
        $(this).find('span').attr('uk-icon', 'chevron-down');
      }
    });

    // Aplica regras caso a página seja carregada em ambiente mobile (< 768)
    if (app.isMobile()) {
      $('body').addClass('mobile');
    }

    // On Scroll
    // ---------
    $(window).scroll(function () {});
  };

  console.log(msgPrefix + 'Aplicação carregada.');
  return app;
}(window.jQuery);

// Executa os métodos iniciais
(function () {
  ucpaweb.init();
  ucpaweb.checkAppsSession();
})();